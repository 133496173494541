<template>
  <div class="users-corbeille">
    <div class="header-user">
      <div class="search-user">
        <span class="searchIcon" title="search">
          <font-awesome-icon icon="search"
        /></span>
        <input
          v-debounce:400="filter"
          class="input-search"
          type="text"
          v-model="full_name"
          placeholder="Rechercher un utilisateur "
        />
      </div>
    </div>
    <div
      v-if="!initLoading && getUserLoading"
      class="three-dots-loading sortloading ml-2"
    >
      Chargement en cours
    </div>
    <div class="body-user">
      <div v-if="initLoading" class="init-loading three-dots-loading">
        Chargement en cours
      </div>
      <div v-else class="content-tab-users">
        <b-table
          small
          show-empty
          id="my-table"
          class="table"
          :items="getUsersCorbeille"
          :fields="fields"
          :current-page="page"
          :per-page="0"
          sort-icon-left
          no-sort-reset
          :sort-by.sync="sort_by"
          :sort-desc.sync="sortDesc"
          no-local-sorting
          @sort-changed="sort"
          empty-text="Il n'y a aucun enregistrement à afficher"
          empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
        >
          <template v-slot:cell(first_name)="data">
            <div class="font-div">
              {{ data.item.full_name }}
            </div>
          </template>
          <template v-slot:cell(email)="data">
            <p class="font-p">{{ data.item.email }}</p>
          </template>
          <template v-slot:cell(type)="data">
            <p class="font-p">{{ data.item.type | typeFormat }}</p>
          </template>
          <template v-slot:cell(team_name)="data">
            <div v-if="data.item.team" class="font-div">
              <span>
                {{ data.item.team.data.name }}
              </span>
              <br />
              <strong class="equipe">
                ( {{ data.item.team.data.type | FormatType }} )
              </strong>
              <strong
                class="type"
                v-if="data.item.depot_id != null && data.item.team"
              >
                - ( {{ data.item.depot.data.name }} )
              </strong>
            </div>
          </template>
          <template v-slot:cell(created_at)="data">
            <p class="font-p">
              {{ data.item ? data.item.created_at.split(',')[0] : '' }}
            </p>
          </template>
          <template v-slot:cell(actions)="data">
            <div class="actions">
              <div v-if="currentUser && currentUser.isSuper">
                <a
                  href="#"
                  class="font"
                  @click.prevent="handleClickRestaurer(data.item)"
                >
                  Restaurer
                </a>
              </div>
              <div v-if="currentUser && currentUser.isSuper">
                <a
                  href="#"
                  class="font"
                  @click.prevent="handleClickDeleteDefinitivement(data.item)"
                >
                  Supprimer définitivement
                </a>
              </div>
            </div>
          </template>
        </b-table>
        <div class="pagination">
          <div v-if="getUserLoading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <b-pagination
            v-model="page"
            :total-rows="usersCorbeilleCount"
            :per-page="per_page"
            align="right"
            size="sm"
            prev-text="Précédent"
            next-text="Suivant"
            aria-controls="my-table"
            @change="pagination"
          ></b-pagination>
        </div>
      </div>
    </div>
    <!-- Supprimer Définitivement User Modal -->
    <b-modal
      ref="userDeletDefinitementModal"
      id="userDeletDefinitementModal"
      no-close-on-backdrop
      title="Supprimer définitivement un utilisateur "
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer l'utilisateur
        <strong class="name">
          {{ userToDelete ? userToDelete.first_name : '' }}
          {{ userToDelete ? userToDelete.last_name : '' }}</strong
        >
        ?
      </p>
      <div class="danger-message mt-2 mb-2">
        <font-awesome-icon class="mr-1" icon="exclamation-circle" /> La
        suppression sera définitive !
      </div>
      <div class="message">
        <div v-if="loading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('userDeletDefinitementModal')"
          variant="outline-secondary"
          >Annuler</b-button
        >
        <b-button variant="success" @click="handleSupprimerDefinitivement"
          >Valider</b-button
        >
      </div>
    </b-modal>
    <!-- END Supprimer Définitivement User Modal -->
    <!-- Restaurer User Modal -->
    <b-modal
      ref="userRestaurerModal"
      id="userRestaurerModal"
      no-close-on-backdrop
      title="Restaurer un utilisateur "
      :hide-footer="true"
    >
      <p>
        Êtes-vous sûr de vouloir restaurer cet utilisateur
        <strong class="name">
          {{ userToRestaurer ? userToRestaurer.first_name : '' }}
          {{ userToRestaurer ? userToRestaurer.last_name : '' }}</strong
        >
        ?
      </p>
      <div class="message">
        <div v-if="loading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('userRestaurerModal')"
          variant="outline-secondary"
          >Annuler</b-button
        >
        <b-button variant="success" @click="handleRestaurerUser"
          >Valider</b-button
        >
      </div>
    </b-modal>
    <!-- END Restaurer User Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  data() {
    return {
      fields: [
        { key: 'first_name', label: 'Nom', sortable: true },
        { key: 'email', label: 'E-mail', sortable: true },
        { key: 'type', label: 'Type', sortable: false },
        { key: 'team_name', label: 'Equipe', sortable: false },
        { key: 'created_at', label: 'Date de création', sortable: false },
        { key: 'actions', label: '', sortable: false }
      ],
      page: 1,
      per_page: 10,
      active: 1,
      sort_by: 'first_name',
      sort_by_desc: 'first_name',
      sortDesc: false,
      loading: false,
      initLoading: true,
      error: null,
      userToDelete: null,
      userToRestaurer: null,
      full_name: null
    }
  },
  methods: {
    ...mapActions([
      'fetchCorbeilleUsers',
      'deleteDefinitivementUser',
      'restaurerUser'
    ]),
    sort(sort) {
      this.sort_by = sort.sortBy
      this.sort_by_desc = sort.sortBy
      if (!sort.sortDesc) {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    pagination(paginate) {
      this.page = paginate
      if (!this.sortDesc) {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    filter() {
      this.page = 1
      if (!this.sortDesc) {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
    handleClickRestaurer(user) {
      this.userToRestaurer = user
      this.$refs['userRestaurerModal'].show()
    },
    handleClickDeleteDefinitivement(user) {
      this.userToDelete = user
      this.$refs['userDeletDefinitementModal'].show()
    },
    async handleSupprimerDefinitivement() {
      this.loading = true
      this.error = null
      const response = await this.deleteDefinitivementUser(this.userToDelete)
      if (response.success) {
        this.loading = false
        this.hideModal('userDeletDefinitementModal')
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleRestaurerUser() {
      this.loading = true
      this.error = null
      const response = await this.restaurerUser(this.userToRestaurer)
      if (response.success) {
        this.loading = false
        this.hideModal('userRestaurerModal')
      } else {
        this.loading = false
        this.error = response.error
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUsersCorbeille',
      'getUserLoading',
      'usersCorbeilleCount',
      'currentUser'
    ])
  },
  async mounted() {
    await this.fetchCorbeilleUsers({
      page: this.page,
      per_page: this.per_page,
      sort_by: this.sort_by,
      active: this.active,
      exclude: ['team.manager', 'team.depots', 'visibleTeams']
    })
    this.initLoading = false
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'CERTIGAIA Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'AMO'
        case 'user.sub-super-admin':
          return 'Sous Super Admin'
        case 'user.ingenieur':
          return 'Ingénieur'
        case 'user.visiteur.tech':
          return 'Visiteur Technique'
        case 'user.tech.travaux':
          return 'Technicien Travaux'
        default:
          return ''
      }
    },
    date: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      return value
    },
    FormatType: value => {
      switch (value) {
        case 'ge':
          return 'CERTIGAIA'
        default:
          return value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.users-corbeille {
  .header-user {
    display: flex;
    align-items: center;
    .search-user {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
  .body-user {
    padding-top: 10px;
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .input-search {
    border: 1px solid #dadada;
    outline: none;
    font-size: 16px;
    height: 40px;
    background: #fff;
    padding-left: 10px;
  }
  .searchIcon {
    padding: 10px;
    border: 1px solid #d6d8db;
    font-size: 16px;
    background: #d6d8da17;
  }
  .init-loading {
    padding: 15px 5px;
  }
  .content-tab-users {
    padding-top: 4px;
    .sortloading {
      padding-bottom: 20px;
    }
    table {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
      color: #212529;
      border: 0;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #b9babb;
      margin-top: -8px;
      font-size: 12px;

      tbody {
        .font-div,
        .font-p {
          font-size: 15px;
          margin-top: 0;
          margin-bottom: 0;
          &.font-div {
            text-transform: capitalize;
          }
        }
        .actions {
          text-align: left;
          margin-left: 7px;
          .font {
            font-weight: normal;
            text-transform: capitalize;
            font-size: 14px;
            color: #007bff;
          }
        }
        .icon-exclamation {
          text-decoration: line-through;
          text-decoration-color: #e73e01;
        }
        .name {
          text-transform: capitalize;
        }
        .equipe {
          font-weight: normal;
          text-transform: capitalize;
          font-size: 12px;
          color: #317ae2;
        }
        .type {
          font-weight: normal;
          text-transform: capitalize;
          font-size: 12px;
          color: #317ae2;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      width: 100%;
      .loading {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .header-user {
      .search-user {
        input {
          width: 100%;
        }
      }
    }
    .body-user {
      overflow: auto;
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.content-tab-users {
  .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(3.3rem / 2) center;
    padding-left: calc(1.3rem + -0.35em);
  }
  .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
  }
  .table-sm th,
  .table-sm td {
    vertical-align: middle;
    border: 1px solid #dee2e6;
  }
}
#userDeletDefinitementModal {
  .name {
    text-transform: capitalize;
  }
  .danger-message {
    color: #ff5722;
  }
  .message {
    .error {
      padding: 25px;
      color: #ff5722;
    }
  }
}
</style>
